import { UserRole } from '@features/users/enums/user-role.enum';

export class RolesConstants {
  // Dashboard
  public static DASHBOARD_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
  ];

  // Shipment
  public static SHIPMENT_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
    UserRole.Support,
  ];

  static SHIPMENT_EXPORT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
  ];

  //order
  public static ORDER_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
  ];

  public static SHIPMENT_ADD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
    UserRole.Support,
  ];
  public static SHIPMENT_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
    UserRole.Support,
  ];
  public static SHIPMENT_DELETE = [UserRole.SuperAdmin];
  public static SHIPMENT_DELIVERY_NOTE_ADD_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
    UserRole.Support,
  ];
  public static SHIPMENT_DELIVERY_NOTE_EDIT_PRICES = [UserRole.SuperAdmin];
  public static LIVE_TRACKING_DRIVERS = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
    UserRole.Support,
  ];
  static EDIT_GATE_TO_GATE = [UserRole.SuperAdmin, UserRole.Support];

  public static B2C_SHIPMENTS_VIEW = [UserRole.Admin, UserRole.SuperAdmin, UserRole.Admin_B2C];
  public static B2C_SHIPMENT_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.Admin_B2C,
  ];

  public static LOGS_VIEW = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];

  public static B2C_SHIPMENT_REQUESTS_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.Admin_B2C,
  ];
  public static EXCEPTION_LOGS_VIEW = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];

  // Customer
  public static CUSTOMER_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
    UserRole.Support,
  ];

  static CUSTOMER_EXPORT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
  ];

  public static SELLER_VIEW = [UserRole.Admin, UserRole.SuperAdmin, UserRole.Admin_B2C];

  public static CUSTOMER_ADD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
    UserRole.Support,
  ];
  public static CUSTOMER_DELETE = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
  ];
  public static CUSTOMER_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
    UserRole.Support,
  ];
  public static CUSTOMER_MANAGE_REQUESTS = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
  ];
  public static CUSTOMER_DELETE_CONTRACTS = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
  ];
  public static CUSTOMER_EDIT_TRUCK_OPTION_IN_CONTRACT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
    UserRole.Support,
  ];

  // Fleets
  public static FLEET_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
    UserRole.Support,
  ];
  public static FLEET_ADD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
    UserRole.Support,
  ];
  public static FLEET_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
    UserRole.Support,
  ];

  static FLEET_EXPORT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
  ];
  public static FLEET_DELETE = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
  ];
  public static FLEET_DELETE_CONTRACTS = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
  ];
  public static FLEET_MANAGE_REQUESTS = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
  ];
  public static FLEET_ADD_BULK_DRIVERS_AND_TRUCKS = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
  ];

  public static EXPORT_FLEETS = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];

  // Invoices
  public static INVOICES = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
  ];

  // Reports
  public static REPORTS = [
    UserRole.Admin,
    UserRole.Admin_B2C,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
  ];
  // Reports
  public static REPORTS_B2B = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
  ];
  // Reports
  public static REPORTS_B2C = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
    UserRole.Admin_B2C,
  ];

  // Organization
  public static ORGANIZATION_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
  ];
  public static ORGANIZATION_ADD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
  ];
  public static ORGANIZATION_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
  ];
  public static ORGANIZATION_DELETE = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];

  // User
  public static USER_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
  ];
  public static USER_PARAMS_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.Support,
  ];
  public static USER_PARAMS_ADD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.Support,
  ];
  public static ADMIN_USER_ADD = [UserRole.SuperAdmin];
  public static USER_ADD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
  ];
  public static USER_EDIT = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];
  public static USER_DELETE = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];

  // Lead
  public static LEAD_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
  ];
  public static LEAD_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.Operation,
    UserRole.OperationManager,
  ];
  public static LEAD_DELETE = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];

  // Global Receiver
  public static GLOBAL_RECEIVER_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
  ];
  public static GLOBAL_RECEIVER_ADD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
  ];
  public static SALES_ORDERS_ADD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
  ];
  public static GLOBAL_RECEIVER_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
  ];
  public static GLOBAL_RECEIVER_DELETE = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
  ];
  public static CONTRACT_ALLOW_ALL_TRUCK_TYPES = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
  ];
  public static TRANSPORTATION_PAYMENTS = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
  ];

  //smart detection
  public static SMART_DETECTION_CHECK = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
  ];

  //FleetsB2c
  public static FLEETSB2C_VIEW = [UserRole.Admin, UserRole.SuperAdmin, UserRole.Admin_B2C];
  public static FLEETSB2C_EDIT = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
  ];

  // live tracking view
  public static LIVE_TRACKING_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
    UserRole.Sales,
    UserRole.Supervisor,
    UserRole.Operation,
    UserRole.Support,
  ];

  public static B2C_LIVE_TRACKING_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.Admin_B2C,
  ];

  public static AGGREGATOR = [UserRole.Admin, UserRole.Admin_B2C, UserRole.SuperAdmin];

  //manage view
  public static MANAGE_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
    UserRole.Sales,
    UserRole.Supervisor,
    UserRole.Operation,
  ];

  // setting view
  public static SETTING_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
    UserRole.Sales,
    UserRole.Supervisor,
    UserRole.Operation,
  ];

  // edit user
  public static RESET_PASSWORD = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
    UserRole.Sales,
    UserRole.Supervisor,
    UserRole.Operation,
  ];

  public static APPROVE_USERS = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];

  //my shipments view
  public static MY_SHIPMENTS_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
    UserRole.Sales,
    UserRole.Supervisor,
    UserRole.Operation,
    UserRole.Support,
  ];

  public static USERS_SHIPMENTS_FILTER_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.OperationManager,
    UserRole.Admin_B2C,
    UserRole.Support,
  ];

  public static B2C_RULES_VIEW = [UserRole.Admin, UserRole.SuperAdmin, UserRole.Admin_B2C];

  public static B2C_CONFIGURATION_VIEW = [UserRole.Admin, UserRole.SuperAdmin];

  public static B2C_RULES_CREATE_EDIT = [UserRole.Admin, UserRole.SuperAdmin];

  public static B2C_ZONES_VIEW = [UserRole.Admin, UserRole.SuperAdmin, UserRole.Admin_B2C];

  static SHIPMENT_LIST_VIEW = [];

  static TRIP_PLANNING_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
    UserRole.Operation,
    UserRole.Sales,
    UserRole.Supervisor,
  ];

  static TRIP_PERFORMANCE_VIEW = [UserRole.Admin, UserRole.RentalManager, UserRole.SuperAdmin];
  // Smart Center
  public static SMART_CENTER_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.SalesManager,
  ];

  // Update Shipment to next staus
  public static UPDATE_SHIPMENT_TO_NEXT_STATUS = [
    UserRole.SuperAdmin,
    UserRole.Admin,
    UserRole.Support,
  ];

  public static EDIT_DRAFT_INVOICE_AND_CONVERT_TO_UNPAID = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.SalesManager,
    UserRole.Sales,
  ];

  public static EDIT_CUSTOMER_MOBILE = [UserRole.SuperAdmin];

  public static CHANGE_OPERATIONAL_STATUS = [UserRole.SuperAdmin, UserRole.RentalManager];

  public static ADD_AND_EDIT_RELEASE_NOTES = [UserRole.SuperAdmin];
  public static INVOICE_SUMMARY_VIEW = [
    UserRole.Admin,
    UserRole.Admin_B2C,
    UserRole.FleetB2c,
    UserRole.Operation,
    UserRole.OperationManager,
    UserRole.RentalManager,
    UserRole.Sales,
    UserRole.SalesManager,
    UserRole.SuperAdmin,
    UserRole.Supervisor,
  ];

  public static DELIVERY_NOTE_PRICES_AND_COSTS_VIEW = [
    UserRole.Admin,
    UserRole.RentalManager,
    UserRole.SuperAdmin,
    UserRole.OperationManager,
    UserRole.Operation,
  ];
  public static DALEEL_CONFIGURATION = [UserRole.SuperAdmin];
  public static EDIT_INVOICE_ACTUAL_PAYMENT_DATE = [UserRole.SuperAdmin, UserRole.Admin];
}
