import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from '@shared/enums/language.enum';
import { NavBarItem } from '@shared/model/nav-bar-item';
import { LayoutService } from '@shared/services/layout.service';
import { BaseComponent } from '../base-component/base.component';
import { debounceTime, takeUntil, startWith } from 'rxjs/operators';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { combineLatest } from 'rxjs';
import { JwtDecoderService } from '@core/services/jwt-decoder.service';
import { LocalStorageUtil } from '@shared/utils/local-storage.util';
import { SharedConstants } from '@shared/model/shared-constants';
import { Router } from '@angular/router';
import { LanguageService } from '@shared/services/language.service';
import { MatDialog } from '@angular/material/dialog';
import { OpsScoreDialogComponent } from '@layout/components/ops-score-dialog/ops-score-dialog.component';
import { NavListService } from '@layout/services/nav-list.service';
import { UserRole } from '@features/users/enums/user-role.enum';
import { AnnouncementDialogComponent } from '../announcement-dialog/announcement-dialog.component';
import { ConfigConstant, StorageConstant } from '@shared/constants';

@Component({
  selector: 'app-avatar-Menu',
  templateUrl: './avatar-menu.component.html',
  styleUrls: ['./avatar-menu.component.scss'],
})
export class AvatarMenuComponent extends BaseComponent implements OnInit {
  public userFirstName = '';
  public userLastName = '';
  public userEmail = '';

  userRole: UserRole[];
  language = LanguageEnum;
  currentLang: LanguageEnum;
  public navigationItems: NavBarItem[];
  sideMenuActive = false;
  toggleMenu = false;
  position: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  };
  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    public layoutService: LayoutService,
    private jwtDecoderService: JwtDecoderService,
    private router: Router,
    private languageService: LanguageService,
    private matDialogService: MatDialog,
    public navService: NavListService
  ) {
    super();
    navService.action$.pipe(takeUntil(this.destroy$)).subscribe((action) => {
      if (action === 'myScore') {
        this.openScoreDialog();
      }
      if (action === 'announcement') {
        this.openAnnouncementDialog();
      }
      if (action === 'logout') {
        this.logout();
      }
    });
  }

  ngOnInit() {
    this.userFirstName = this.authService.currentUser$.value?.firstName;
    this.userLastName = this.authService.currentUser$.value?.lastName;
    this.userEmail = this.authService.currentUser$.value?.email;
    this.userRole = this.authService.currentUser$.value.userRoles;
    this.languageService.currentLanguage$.subscribe((lang) => {
      this.currentLang = lang.isoCode;
    });

    this.layoutService.websiteDirection$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.setupNavBarItems();
    });
  }

  logout(): void {
    this.jwtDecoderService.removeCurrentToken();
    LocalStorageUtil.removeItem(SharedConstants.RENTAL_PROFIT_FAV);
    LocalStorageUtil.removeItem(StorageConstant.COLUMN_OPTIONS_KEY);
    this.router.navigate([RoutesUtil.AuthLogin.url()]);
  }

  setLanguage(language: LanguageEnum): void {
    this.languageService.changeLanguage(language);
  }

  openScoreDialog(): void {
    this.matDialogService.open(OpsScoreDialogComponent);
  }

  openAnnouncementDialog(): void {
    this.matDialogService.open(AnnouncementDialogComponent, {
      width: '550px',
    });
  }

  private setupNavBarItems(): void {
    combineLatest([this.translateService.get(['avatarMenu'])])
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe(([translations]: any) => {
        const { avatarMenu } = translations;

        this.navigationItems = [
          {
            title: avatarMenu.MyScore,
            icon: 'chart',
            action: 'myScore',
            isVisible: true,
          },

          {
            title: avatarMenu.MyTickets,
            icon: 'bulk-ticket',
            isVisible: true,
            path: RoutesUtil.SupportTickets.url(),
          },
          {
            title: avatarMenu.Announcement,
            icon: 'bulk-star',
            action: 'announcement',
            isVisible: this.userRole.includes(UserRole.SuperAdmin || UserRole.Admin),
          },
          // {
          //   title: avatarMenu.ReleaseNotes,
          //   icon: 'bulk-star',
          //   path: RoutesUtil.ReleaseNotes.url(),
          //   isVisible: true,
          // },
          {
            title: avatarMenu.Payment,
            icon: 'money',
            isVisible: true,
          },
          {
            title: `${avatarMenu.CustomerSupport}<small class="d-block text-400">☏ ${ConfigConstant.CUSTOMER_SUPPORT_NUMBER}</small>`,
            path: RoutesUtil.SupportAddTicket.url(),
            icon: 'headphone',
            isVisible: true,
          },
          {
            title: avatarMenu.Settings,
            path: RoutesUtil.Settings.url(),
            icon: 'settings',
            isVisible: true,
          },
          {
            title: avatarMenu.logout,
            icon: 'logout',
            action: 'logout',
            isVisible: true,
          },
        ];
      });
  }
}
