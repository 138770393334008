export class ConfigConstant {
  static VERSION = '3.28.0';
  static APP_TITLE = 'Madar Admin';
  static APP_LANGUAGE_COOKIE_KEY = 'appLang';
  static PAGE_SIZE = 20;
  static SHIPMENT_PAGE_SIZE = 30;
  static DELIVERY_NOTES_PAGE_SIZE = 1000;
  static INIT_PAGE_INDEX = 1;
  static INIT_PAGE_OFFSET = 0;
  static NOTIFICATION_LIMIT = 10;
  static defaultLatitude = 24.774265;
  static defaultLongitude = 46.738586;
  static defaultSpeedLimit = 70;
  static MadarFreelanceKeyword = 'Freelance';
  static FEATURE_FLAG_KEY = 'example-context-key';

  static FILE_MAX_SIZE = 2000000;

  static SAUDI_ARABIA_ISO_CODE = 'KSA';

  // TODO: will be removed after firebase integration
  static notificationToken =
    'faT0L2ESFgYKJHSzSbMVzN:APA91bEwqkr77LvQpFSBEXIcr5t_BgYg7DNFPhgXkahKDg_I5W7qRueAhDZV8zEr2Zc8SjDhBuIO3N9Rosi8E11GLNHIGdNVueGG';
  static MOBILE_NUMBER_SEPARATOR = '-';

  static MAX_FILE_SIZE = 10 * 1024 * 1024;
  static COMMERCIAL_FILE_SIZE = 2 * 1024 * 1024;
  static CUSTOMER_SUPPORT_NUMBER = '9200 31397';

  static APP_VERSION_KEY = 'VERSION';

  static APP_WHITE_LISTED_DOMAINS = [
    // dev urls
    'madarv2-dev-app-backend-main.azurewebsites.net',
    'madarv2-dev-app-backend-micro-mobiletracking.azurewebsites.net',
    // test urls
    'madarv2-test-app-backend-main-02.azurewebsites.net',
    'madarv2-test-app-backend-micro-mobiletracking.azurewebsites.net',
    // stage
    'madarv2-stag-app-backend-main.azurewebsites.net',
    // prod urls
    'madarv2-prod-app-backend-main.azurewebsites.net',
    'madarv2-prod-app-backend-micro-mobiletracking.azurewebsites.net',
    // preprod urls
    'madarv2-prod-app-backend-main-preprod.azurewebsites.net',

    '1hxx7v6k-3000.euw.devtunnels.ms',
    'https://1hxx7v6k-3000.euw.devtunnels.ms/',
  ];
}
