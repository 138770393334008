import { Phrases } from '@shared/model/locales.model';

export default {
  welcome: 'Welcome',
  'Generic.Amount': '{{amount}} SAR',
  'Buttons.Cancel': 'Cancel',
  'Buttons.CancelOrder': 'Cancel Order',
  'Buttons.Submit': 'Submit',
  'Buttons.Reset': 'Reset',
  'Buttons.Apply': 'Apply',
  'Buttons.Save': 'Save',
  'Buttons.Export': 'Export',
  'Buttons.Print': 'Print',
  'Buttons.Details': 'Details',
  'Buttons.Confirm': 'Confirm',
  'Buttons.Add': 'Add',
  'Buttons.Update': 'Update',
  'Buttons.Edit': 'Edit',
  'Buttons.Delete': 'Delete',
  'Buttons.Remove': 'Remove',
  'Buttons.Search': 'Search',
  'Buttons.Find': 'Find',
  'Buttons.Continue': 'Continue',
  'Buttons.Next': 'Next',
  'Buttons.SelectFile': 'Select File',
  'Buttons.Clone': 'Clone',
  'ColumnOptions.CustomTitle': 'Custom Column Options',
  'ColumnOptions.Title': 'Column Options',
  'ColumnOptions.ApplyMyColumnSettings': 'Apply my columns settings',
  'ColumnOptions.SettingsHint': 'You have up to 3 columns settings for quick usage',
  'ColumnOptions.CreateCustomColumnSettings': 'Create custom column settings',
  'ColumnOptions.BackToColumnSettings': 'Back to column settings',
  'ColumnOptions.NoCustomAdded': 'No custom settings added',
  'ColumnOptions.ColumnSettingName': 'Column setting name',
  'ColumnOptions.DisplayedColumns': 'Displayed columns ({{count}})',
  'ColumnOptions.NonDisplayedColumns': 'Non-Displayed columns ({{count}})',
  'ColumnOptions.SelectToDisplay':
    '<b>Select to display,</b> This field is about adding another column to your content.',
  'ColumnOptions.NameYourColumnSetting': 'Name your column setting',
  'ColumnOptions.DisplayAll': 'Display All',
  'cardItemsList.Orders': 'Orders',
  'cardItemsList.Pallet': 'Pallet',
  'cardItemsList.Items': 'Items',
  'cardItemsList.PalletItems': 'Pallet Items',
  'cardItemsList.LineItems': 'Line Items',
  'cardItemsList.NoPalletSelected': 'No Pallet selected',
  'cardItemsList.OnceYouSelectTheDetailsWillAppearHere':
    'Once you select, the details will appear here.',
  'cardItemsList.Qty': 'Qty:',
  'cardItemsList.Batch': 'Batch:',
  'cardItemsList.Accepted': 'Accepted',
  'cardItemsList.Returned': 'Returned',
  'cardItemsList.EmptyPalletHint': 'Once you select, the details will appears here.',
  activity: 'Activity',
  'activity.TheInvoice': 'the invoice',
  'activity.MarkedAsPaid': 'Marked the invoice as Paid',
  'activity.Converted': 'Converted The Invoice to Over Due',
  'confirmation.cancelTitle': 'Confirm cancel!',
  'confirmation.cancelText':
    'Are you sure you want to cancel this? All filled data will permanently removed and cannot be undone.',
  'confirmation.cancelDiscard': 'No, Keep it',
  'confirmation.cancelConfirm': 'Yes, Cancel',
  'confirmation.discard': 'discard',
  'confirmation.confirm': 'confirm',
  'confirmation.YesConfirm': 'Yes, Confirm',
  'confirmation.NoCancel': 'No, Cancel',
  'lazyDropdown.all': 'All {{items}}',
  'Enum.InvoiceAction.CREATED': 'Created',
  'Enum.InvoiceAction.UPDATED': 'Updated',
  'Enum.InvoiceAction.APPROVED': 'Approved',
  'Enum.InvoiceAction.REJECTED': 'Rejected',
  'Enum.InvoiceAction.PAID': 'Paid',
  'Enum.ShipmentsColumns.ID': 'ID',
  'Enum.ShipmentsColumns.NUMBER_OF_REQUESTS': 'Number of Requests',
  'Enum.ShipmentsColumns.FEES': 'Fees',
  'Enum.ShipmentsColumns.TRIP': 'Trip',
  'Enum.ShipmentsColumns.STATUS': 'Status',
  'Enum.ShipmentsColumns.CREATED_BY': 'Created By',
  'Enum.ShipmentsColumns.FLEETB2C': 'FleetB2c',
  'Enum.ShipmentsColumns.TRUCK_TYPE': 'Truck Type',
  'Enum.ShipmentsColumns.DRIVER_NAME': 'Driver Name',
  'Enum.ShipmentsColumns.DRIVER_ALLOWANCE': 'Driver Allowance',
  'Enum.ShipmentsColumns.ASSIGNED_BY': 'Assigned By',
  'Enum.ShipmentsColumns.TRACKING_TYPE': 'Tracking Type',
  'Enum.ShipmentsColumns.DELIVERY_NOTE': 'Delivery Note',
  'Enum.ShipmentsColumns.NOTES': 'Notes',
  'Enum.columnOptions.id': 'ID',
  'Enum.columnOptions.numberOfRequests': 'number of requests',
  'Enum.columnOptions.RequestedDeliveryDate': 'Requested Delivery Date  ',
  'Enum.columnOptions.fees': 'fees',
  'Enum.columnOptions.trip': 'trip',
  'Enum.columnOptions.status': 'status',
  'Enum.columnOptions.createdBy': 'created by',
  'Enum.columnOptions.FleetsB2c': 'FleetsB2c',
  'Enum.columnOptions.truckType': 'truck type',
  'Enum.columnOptions.driverName': 'driver name',
  'Enum.columnOptions.driverAllowance': 'driver allowance',
  'Enum.columnOptions.assignedBy': 'assigned by',
  'Enum.columnOptions.trackingType': 'tracking type',
  'Enum.columnOptions.deliveryNote': 'delivery note',
  'Enum.columnOptions.notes': 'notes',
  'Enum.columnOptions.createdAt': 'created at',
  'Enum.columnOptions.customerName': 'customer name',
  'Enum.columnOptions.from': 'from',
  'Enum.columnOptions.to': 'to',
  'Enum.columnOptions.cost': 'cost',
  'Enum.columnOptions.price': 'price',
  'Enum.columnOptions.margin': 'margin',
  'Enum.columnOptions.mobileNumber': 'mobile number',
  'Enum.columnOptions.iqamaId': 'iqama id',
  'Enum.columnOptions.driverId': 'driver id',
  'Enum.columnOptions.name': 'name',
  'Enum.columnOptions.iqama': 'iqama',
  'Enum.columnOptions.sponsor': 'sponsor',
  'Enum.columnOptions.noOfShipment': 'number of shipment',
  'Enum.columnOptions.driverMobileNumber': 'driver mobile number',
  'Enum.columnOptions.truckId': 'truck id',
  'Enum.columnOptions.truckSponsor': 'truck sponsor',
  'Enum.columnOptions.plateNumber': 'plate number',
  'Enum.columnOptions.model': 'model',
  'Enum.columnOptions.Sponsor': 'sponsor',
  'Enum.OrderType.StockToCustomerStock': 'Stock Transfer',
  'Enum.OrderType.StoTransfer': 'Stock Transfer',
  'Enum.OrderType.Z122': 'Stock Transfer',
  'Enum.OrderType.CustomerOutboundOrders': 'Outbound Transfer',
  'Enum.OrderType.CustomerReturn': 'Customer Return',
  'Enum.OrderType.CustomerReturnNupcoStock': 'Customer Return Nupco Stock',
  'Enum.OrderType.NupcoSalesReturns': 'Nupco Sales Returns',
  'Enum.OrderType.DistributionOutboundOrder': 'Distribution Outbound Order',
  'Enum.OrderType.TransferOrders': 'Transfer Orders',
  'Enum.OrderType.VendorSupplierReturns': 'Vendor Supplier Returns',
  'Enum.OrderType.SalesOrder': 'Sales Order',
  'Enum.OrderType.NupcoRoyalCourt': 'Nupco Royal Court',
  'Enum.OrderType.NupcoDonationIssue': 'Nupco Donation Issue',
  'Enum.OrderType.ScrapOrders': 'Scrap Orders',
  'Enum.OrderType.PrepareOrder': 'Prepare Order',
  'Enum.OrderType.SalesWithPrice': 'Sales With Price',
  'Enum.OrderType.ZPG1': 'ZPG1',
  'Enum.OrderType.MOIR': 'MOI-R',
  'sortMenu.MostRecent': 'Most Recent',
  'sortMenu.NameAZ': 'Low to High',
  'sortMenu.NameZA': 'High to Low',
  'sortMenu.sort': 'Sort',
  'sortMenu.all': 'All {{items}}',
  'sortMenu.REVENUE': 'By Revenue',
  'sortMenu.COST': 'By Cost',
  'sortMenu.PROFIT': 'By Profit',
  'sortMenu.All': 'All',
  'sortMenu.MOBILE': 'Mobile',
  'sortMenu.AVL': 'Avl',
  'sortMenu.EMPTY': 'No Tracking',
  'sortMenu.SERVICE': 'OPS',
  'sortMenu.SAAS': 'SAAS',
  'sortMenu.SINGLE': 'Single',
  'sortMenu.MULTIPLE': 'Multiple',
  'sortMenu.VERIFIED': 'Verified',
  'sortMenu.NOT_VERIFIED': 'NotVerified',
  'sortMenu.HighNoOfPallets': 'high No.Of Pallets',
  'sortMenu.LowNoOfPallets': 'low No. Of Pallets',
  'sortMenu.ALL': 'ALL',
  'sortMenu.AMB': 'AMB',
  'sortMenu.Chiller': 'Chiller',
  'sortMenu.Frozen': 'Frozen',
  'sortMenu.Deep_Frozen': 'Deep Frozen',
  'accordion.OrderID': 'Order ID:',
  'accordion.Pallet': 'Pallet',
  'accordion.Items': 'Items',
  'accordion.All': 'All',
  'accordion.Orders': 'Orders',
  'accordion.ProofOfDelivery': 'Proof of delivery',
  'accordion.Download': 'Download',
  'accordion.SealNo': 'Seal No',
  AutoUpdate: 'Auto Update',
  AutoCache: 'Auto Cache',
  'Errors.NetworkError':
    'Network Disconnected, please check your connection and reload the app again!.',
  'Placeholder.EnterTheValue': 'Enter the value',
  'Placeholder.SelectValue': 'Select Value',
  'Placeholder.DottedSearch': 'Search...',
  'Placeholder.WriteTheValue': 'Write the value',
  'ConfirmActivateResetPassword.Title': 'Activate and reset password',
  'ConfirmActivateResetPassword.Message':
    'Are you sure you want to activate this User and reset the password with the default value?',
  'ConfirmResetPassword.Title': 'You are about to reset password',
  'ConfirmResetPassword.Message': 'Are you sure?',
  'showPassword.Password': 'Password : ',
  'showPassword.Changed': 'Changed',
  'showPassword.NotChangedYet': 'Not changed yet',
  'showPassword.ShowPassword': 'Show Password',
  'showPassword.HiddenPassword': 'Hidden Password',
  'GenerateComplexPasswordDialog.passwordGenerated': 'Password Generated',
  'GenerateComplexPasswordDialog.passwordGeneratedText':
    'As part of our security measures, the system has automatically generated a password for this account, which has been sent to the email address provided.',
  'announcementBar.news': 'News',
  'announcementBar.link': 'Link',
  'announcementDialog.announcement': 'Announcement',
  'announcementDialog.announcementText':
    'Apply the text that will be shown on the announcement bar.',
  'announcementDialog.text': 'Text',
  'announcementDialog.link': 'Link',
  'announcementDialog.linkName': 'Link Name',
  'announcementDialog.toBeShownFor': 'To be shown for',
  'announcementDialog.admin': 'Admin',
  'announcementDialog.customer': 'Customer',
  km: 'KM',
  hrs: 'hrs',
  min: 'min',
  'currency.SAR': 'SAR',
  'currency.EGP': 'EGP',
  'TransactionHistory.changingLog': 'Changing Log',
  'TransactionHistory.changingLogDetails':
    'It appears that there have been some changes to the trip cost. Here is the update history for your reference.',
  'TransactionHistory.current': 'Current',
  'TransactionHistory.cost': 'Cost',
  'TransactionHistory.price': 'Price',
  'TransactionHistory.PriceContracted': 'Contracted',
  'TransactionHistory.PriceManual': 'Manual',
  'Hint.DragAndDropFiles': 'Drag and drop files here',
  'Validation.required': '{{field}} is required!',
  'Validation.realNumberValidator': '{{field}} only Number!',
  'Validation.min': '{{field}} should be {{min}} or more!',
  'Validation.max': '{{field}} should be {{max}} or less!',
  'Validation.minLength': '{{field}} should be at least {{requiredLength}} characters!',
  'Validation.minlength': '{{field}} should be at least {{requiredLength}} characters!',
  'Validation.duplicated': '{{field}} is duplicated!',
  'Validation.duplicatedProjection': '{{field}} {{duplicatedProjection}} is duplicated!',
  'Validation.maxLength': '{{field}} should be at most {{requiredLength}} characters!',
  'Validation.maxlength': '{{field}} should be at most {{requiredLength}} characters!',
  'Search.EndOfResults': 'End of results',
} as Phrases;
