import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-uploaded-file',
  templateUrl: './uploaded-file.component.html',
  styleUrls: ['./uploaded-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadedFileComponent {
  @Input() title: string;
  @Input() item: string;
  @Output() downloadClicked = new EventEmitter();

  onDownloadClicked(): void {
    if(this.item){
      window.open(this.item, '_blank');
    }
    else{
      this.downloadClicked.emit({});
    }
  }
}
