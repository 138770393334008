import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';
import { WccIntegrationStatusType } from '@shared/enums/wcc-integration-status-type.enum';
import { ShipmentCreationTemplateTypes } from '@features/shipment-creation-template/enums/shipment-creation-template-types.enum';
import { ShipmentStatus } from '@features/shipments/enums/shipment-status.enum';
import { ShipmentCreationType } from '@features/shipments/enums/shipment-creation-type.enum';

export class ShipmentList extends Model {
  @Expose() ReceiverId: number;
  @Expose() ReceiverName: string;
  @Expose() cost: number;
  @Expose() createdAt: number;
  @Expose() createdByFirstName: string;
  @Expose() createdByLastName: string;
  @Expose() creatorType: string;
  @Expose() customerId: number;
  @Expose() customerName: string;
  @Expose() driverFirstName: string;
  @Expose() driverId: number;
  @Expose() driverLastName: string;
  @Expose() fleetId: number;
  @Expose() fleetName: string;
  @Expose() from: string;
  @Expose() id: number;
  @Expose() iqamaId: string;
  @Expose() margin: number;
  @Expose() nonResidentId: number;
  @Expose() price: number;
  @Expose() to: string;
  @Expose() status: ShipmentStatus;
  @Expose() lastActionDate: number;
  @Expose() mobileNumber: number;
  @Expose() AssignedByFirstName: number;
  @Expose() AssignedByLastName: number;
  @Expose() AssignedByUserType: number;
  @Expose() deliveryNoteId: number;
  @Expose() hasDeliveryNote: number;
  @Expose() hasCompletedAttachments: number;
  @Expose() pickUpDate: number;
  @Expose() numberOfRequests: number;
  @Expose() googleDistance: number;
  @Expose() isAvlTrackingAvailable: boolean;
  @Expose() isMobileTrackingAvailable: boolean;
  @Expose() labels: string;
  @Expose() notes: string;
  @Expose() product: string;
  @Expose() trailerDoorNumber: string;
  @Expose() truckPlateNumber: string;
  @Expose() truckTypeName: string;
  @Expose() shipmentCreationTemplate: ShipmentCreationTemplateTypes;
  @Expose() dropOffCustomerId: number;
  @Expose() dropOffCustomerName: string;
  @Expose() dropOffLocationAddress: string;
  @Expose() dropOffLocationName: string;
  @Expose() dropOffUserId: number;
  @Expose() dropOffUserName: string;
  @Expose() shipmentCreationType: ShipmentCreationType;
  @Expose() delayedAction: boolean;
  @Expose() wccIntegrationStatus: WccIntegrationStatusType;
  @Expose() driverAssignCount: boolean;
  @Expose() isCheckedIn: boolean;
  @Expose() hasTransactionChanges?: boolean;
  @Expose() deliveryDate: number;
}
