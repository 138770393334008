<div class="search-input-wrapper">
  <form [formGroup]="form" class="form">
    <app-row class="" [class]="showSearchActions || searchKeyItems.length ? null : 'no-gutters'">
      <app-col *ngIf="!multiple">
        <mat-form-field class="w-100" appearance="outline" [class.search-input-wrapper__right-position]="iconPosition === 'right'">
          <input class="custom-placeholder" formControlName="searchTerm" matInput type="search" [placeholder]="placeholder" [type]="inputType" />
          <app-icon
            matPrefix
            class="cursor-pointer icon mr-1"
            name="close-circle"
            size="sm"
            (click)="onClearButtonClicked()"
            *ngIf="form.value?.searchTerm"
          ></app-icon>
          <app-icon matPrefix name="search" class="icon" size="sm"></app-icon>

          <mat-error
            *ngIf="
                form.invalid &&
                form.get('searchTerm').touched &&
              form.get('searchTerm').hasError('whitespace') || form.get('searchTerm').hasError('pattern')"
          >
            {{ "ErrorMessages.InValidData" | translate }}
          </mat-error>
        </mat-form-field>
      </app-col>

      <app-col *ngIf="multiple" class="px-0">
        <mat-form-field
          class="form-field-with-chips w-100"
          appearance="outline"
          [class.search-input-wrapper__right-position]="iconPosition === 'right'"
        >
          <app-icon matPrefix name="search" class="search-icon" size="sm"></app-icon>

          <mat-chip-grid #chipList>
            <div
              class="chip-wrapper"
              [matTooltip]="showValueAsTooltip ? stringSearchValue : null"
              matTooltipPosition="above"
              [ngClass]="{'scrollable scrollable--x scroll-chip-wrapper': showValueAsTooltip}"
            >
              <mat-chip-row *ngFor="let searchTerm of (form.value.searchTermList || [])" (removed)="removeSearch(searchTerm)">
                <span [ngClass]="{'text-warn': notFoundValuesSet.has(searchTerm)}">{{searchTerm}}</span>
                <button matChipRemove>
                  <app-icon name="cancel" type="MaterialDesign"></app-icon>
                </button>
              </mat-chip-row>
            </div>

            <input
              #input
              [placeholder]="placeholder"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addSearchTerm($event, input)"
            />
          </mat-chip-grid>

          <app-icon class="cursor-pointer" name="close-circle" (click)="onClearButtonClicked()" *ngIf="form.value?.searchTermList?.length"></app-icon>
        </mat-form-field>
      </app-col>

      <app-col *ngIf="searchKeyItems.length" class="px-0" cols="3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="searchKey">
            <mat-option *ngFor="let searchKeyItem of searchKeyItems" [value]="searchKeyItem">
              {{ "Enum.SearchKey." + searchKeyItem.dropDownDisplayName | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-col>

      <app-col class="pt-1" [cols]="ColsNumber" *ngIf="showSearchActions">
        <div class="action">
          <div class="col-auto">
            <button mat-flat-button [disabled]="form.invalid" color="primary" (click)="onSearchButtonClicked()" [ngStyle]="searchStyles">
              {{ buttonText | translate }}
            </button>
          </div>

          <!--          <div class="col-auto">-->
          <!--            <button mat-flat-button class="btn-secondary" (click)="onClearButtonClicked()">{{ "Buttons.Clear" | translate }}</button>-->
          <!--          </div>-->
        </div>
      </app-col>
    </app-row>
  </form>
</div>
