import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-marker',
  template: `
    <div class="marker__shadow"></div>
    <span class="marker__circle"></span>
    <div class="marker__shape" [style.background-image]="icon || ''"></div>
    <div class="marker__content">
      <span class="marker__content-circle"></span>
      <ng-content></ng-content>
    </div>
  `,
  host: { '[class]': 'classNames' },
  styleUrls: ['./marker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarkerComponent implements OnChanges {
  @Input() color:
    | 'white'
    | 'primary-light'
    | 'primary'
    | 'error'
    | 'success'
    | 'grey'
    | 'warn'
    | 'default'
    | 'accent'
    | 'black' = 'white';
  @Input() size: 'xs' | 'md' | 'sm' | 'lg';
  @Input() active: boolean;
  @Input() icon: string;
  @Input() ngClass: string | string[] | { [key: string]: boolean };

  get classNames() {
    const colorClass = `${this.color} ${this.size ? 'marker--' + this.size : ''}`;
    const iconClass = `${this.icon ? 'marker--has-icon' : ''}`;
    const activeClass = `${this.active ? 'marker--active' : ''}`;
    return `marker marker--${this.color} ${colorClass} ${iconClass} ${activeClass} ${this.ngClass}`;
  }
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.changeDetectorRef.markForCheck();
  }
}
